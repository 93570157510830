import React, { useState, useEffect } from "react";
import Row from "react-bootstrap/Row";
import Card from "react-bootstrap/Card";
import Col from "react-bootstrap/Col";
import { useStaticQuery, graphql, Link } from "gatsby";
import Container from "react-bootstrap/Container";
import Badge from "react-bootstrap/Badge";

export default function IVVideosModule({ page }) {
  const data = useStaticQuery(graphql`
    query IVModuleVideos {
      limit: allMdx(
        filter: { frontmatter: { tags: { eq: "integral-voices" } } }
        limit: 4
      ) {
        nodes {
          id
          slug
          frontmatter {
            headline
            subheadline
            thumbnail
            metadata {
              Description
            }
            podcastCard {
              cardImage
              hosts
              hostThumbnails
              runtime
            }
          }
        }
      }
    }
  `);

  return (
    <Row className="student-profiles integral-voices justify-content-around">
      {data.limit.nodes.map((node, index) => (
        <Col xs={12} sm={5} xl={3} className="pt-0 mb-4" key={index}>
          <Link to={`/${node.slug}`}>
            <div className="video-thumb mb-4">
              <img
                src={node.frontmatter.thumbnail}
                className="img-fluid"
                alt={`${node.frontmatter.headline} thumbnail`}
              />
              <div className="run-time">
                {node.frontmatter.podcastCard.runtime}
              </div>
              <div className="play-icon">
                <span>
                  <svg
                    width="12"
                    height="15"
                    viewBox="0 0 12 15"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M0.947704 0.684082V14.4096L11.732 7.54683L0.947704 0.684082Z"
                      fill="white"
                    />
                  </svg>
                </span>
              </div>
            </div>
            <h4 className="mb-2">
              {node.frontmatter.headline} {node.frontmatter.subheadline}
            </h4>
            <p>{node.frontmatter.metadata.description}</p>
          </Link>
        </Col>
      ))}
    </Row>
  );
}
