import * as React from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { useStaticQuery, graphql, Link } from "gatsby";

function StudentProfileVideos({ page }) {
  const data = useStaticQuery(graphql`
    query StudentProfileVideos {
      limit: allMdx(
        filter: { frontmatter: { tags: { eq: "student" } } }
        sort: { fields: frontmatter___publishedDate, order: DESC }
        limit: 4
      ) {
        nodes {
          id
          slug
          frontmatter {
            headline
            videoThumb
            videoLink
            title
            subheadline
            target
            runTime
            publishedDate
          }
        }
      }
      full: allMdx(
        filter: { frontmatter: { tags: { eq: "student" } } }
        sort: { fields: frontmatter___publishedDate, order: DESC }
      ) {
        nodes {
          id
          slug
          frontmatter {
            headline
            videoThumb
            videoLink
            title
            subheadline
            target
            runTime
          }
        }
      }
    }
  `);

  if (page === "home") {
    return (
      <Row className="student-profiles justify-content-around">
        {data.limit.nodes.map((node, index) => (
          <Col xs={12} sm={5} xl={3} className="pt-0 mb-4" key={index}>
            <Link to={`/${node.slug}`}>
              <div className="video-thumb mb-4">
                <img
                  src={node.frontmatter.videoThumb}
                  className="img-fluid"
                  alt={`${node.frontmatter.title} thumbnail`}
                />
                <div className="run-time">{node.frontmatter.runTime}</div>
                <div className="play-icon">
                  <span>
                    <svg
                      width="12"
                      height="15"
                      viewBox="0 0 12 15"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M0.947704 0.684082V14.4096L11.732 7.54683L0.947704 0.684082Z"
                        fill="white"
                      />
                    </svg>
                  </span>
                </div>
              </div>
              <h4 className="mb-2">{node.frontmatter.headline}</h4>
              <p>{node.frontmatter.subheadline}</p>
            </Link>
          </Col>
        ))}
      </Row>
    );
  } else {
    return (
      <Row className="student-profiles justify-content-around">
        {data.full.nodes.map((node, index) => (
          <Col xs={12} sm={5} xl={3} className="pt-0 mb-4" key={index}>
            <Link to={`/${node.slug}`}>
              <div className="video-thumb mb-4">
                <img
                  src={node.frontmatter.videoThumb}
                  className="img-fluid"
                  alt={`${node.frontmatter.title} thumbnail`}
                />
                <div className="run-time">{node.frontmatter.runTime}</div>
                <div className="play-icon">
                  <span>
                    <svg
                      width="12"
                      height="15"
                      viewBox="0 0 12 15"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M0.947704 0.684082V14.4096L11.732 7.54683L0.947704 0.684082Z"
                        fill="white"
                      />
                    </svg>
                  </span>
                </div>
              </div>
              <h4 className="mb-2">{node.frontmatter.headline}</h4>
              <p>{node.frontmatter.subheadline}</p>
            </Link>
          </Col>
        ))}
      </Row>
    );
  }
}

export default StudentProfileVideos;
